import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const HumboldtGremberg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.HumboldtGremberg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Humboldt/Gremberg"
          d="M725,710l11,24l-8,8l-28,2l-16-2l-20-40l-38-6l-16-8l24-38C657.6,663.8,706.1,690.6,725,710z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 619.6603 678.944)"
          textRendering="geometricPrecision"
        >
          <tspan x="0" y="0">
            Humboldt/
          </tspan>
          <tspan x="0" y="14.4">
            Gremberg
          </tspan>
        </text>
      </g>
    </>
  );
});
